
import HeroBanner from './HeroBanner';
import TrackingForm from './TrackingForm';
import HowItWorks from './HowItWorks';
import RequestInq from './RequestInq';
import LowerHeader from './LowerHeader';
import Footer from './Footer';
// import NoticeModal from './components/NoticeModal';
import DownloadApp from './components/DownloadApp';

const Home = () => {
    return <>
        {/* <NoticeModal /> */}
        <HeroBanner>
            <TrackingForm />
        </HeroBanner>
        <HowItWorks />
        <DownloadApp />
        <RequestInq />
        <LowerHeader />
        <Footer />
    </>
}

export default Home;